<template>
  <div class="table-header">
    <el-button type="primary" @click="handleInsertDataClick"
      >新增健康证</el-button
    >
  </div>

  <!-- 列表 -->
  <div class="table-list">
    <el-table
      :data="state.tableData"
      style="width: 100%"
      v-loading="state.tableLoading"
    >

      <el-table-column prop="image" label="健康证">
        <template #default="scoped">
          <el-avatar
            :size="100"
            :src="scoped.row.image"
            shape="square"
            @click="handlePreImage(scoped.row.image)"
          />
        </template>
      </el-table-column>

      <el-table-column prop="dqsj" label="到期时间" />


      
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scoped">
          <div class="operations">
            <el-button
              link
              type="primary"
              size="small"
              @click="handleDeleteData(scoped.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 分页 -->
  <div class="table-pagination">
    <h5>共{{ state.paginationTotal }}条数据</h5>
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page="state.paginationPage"
      :total="state.paginationTotal"
      :disabled="state.paginationDisabled"
      @current-change="handleCurrentClick"
    />
  </div>

  <!-- 新增健康证 -->
  <el-dialog v-model="state.insertDialogVisible" title="新增健康证">
    <el-form :model="state.insertDialogForm">
      <el-form-item label="健康证图片" label-width="160px">
        <el-upload
          class="avatar-uploader"
          drag
          action="https://jg.gfkjzjs.top/api/upload"
          :show-file-list="false"
          :on-success="handleUploadImageSuccess"
        >
          <img
            v-if="state.insertDialogForm.url"
            :src="state.insertDialogForm.url"
            class="avatar"
          />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="过期时间" label-width="160px">
        <el-date-picker
          v-model="state.insertDialogForm.time"
          type="date"
          placeholder="请选择过期时间"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.insertDialogVisible = false"> 取消 </el-button>
        <el-button type="primary" @click="handleInsertData"> 新增 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive } from "vue";
import { reqGetJkz, reqInsertJkz, reqDelJkz } from "@/api";
import { useRoute } from "vue-router";
const route = useRoute();
console.log();
const _ = require("lodash");

const state = reactive({
  tableLoading: false,
  tableData: [],
  tableSearchText: "",

  paginationPage: 1,
  paginationTotal: 0,
  paginationDisabled: false,

  insertDialogVisible: false,
  insertDialogForm: {
    //到期时间
    time: "",
    //健康证链接
    url: "",
  },
});

const handlePagingQuery = async () => {
  state.tableData = [];
  state.tableLoading = true;
  state.paginationDisabled = true;
  try {
    const res = await reqGetJkz({
      page: state.paginationPage,
      limit: 10,
      id: route.query.id,
    });
    // state.paginationTotal = res.data.total;
    res.data.list.forEach((v) => {
      state.tableData.push(v);
    });
    console.log("总返回条数", res.data);
  } catch (error) {
    console.log(error);
  } finally {
    state.paginationDisabled = false;
    state.tableLoading = false;
  }
};

//点击页
const handleCurrentClick = function (i) {
  state.paginationPage = i;
  handlePagingQuery();
};

//搜索
const handleSearchUid = () => {
  state.paginationPage = 1;
  handlePagingQuery();
};

const handlePreImage = (url) => {
  window.open(url);
};
/*********************************************增删改查*********************************************/

const handleUploadImageSuccess = (e) => {
  state.insertDialogForm.url = e;
};

const handleInsertDataClick = () => {
  state.insertDialogForm.url = "";
  state.insertDialogForm.time = "";
  state.insertDialogVisible = true;
};

const handleInsertData = async () => {
  if (state.insertDialogForm.url === "") {
    ElMessage.error("请上传健康证");
    return;
  }
  if (state.insertDialogForm.time === "") {
    ElMessage.error("请选择健康证过期时间");
    return;
  }

  try {
    const res = await reqInsertJkz({
      ...state.insertDialogForm,
      id: route.query.id,
    });
    console.log("新增成功", res);
    ElMessage.success("新增成功");
    handlePagingQuery();
  } catch (error) {
    console.log(error);
  } finally {
    state.insertDialogVisible = false
  }
  // ElMessageBox.prompt(`请输入小区名称`, `新增小区`, {
  //   confirmButtonText: "新增",
  //   cancelButtonText: "取消",
  //   inputValue: "",
  // }).then(async ({ value }) => {
  //   try {
  //     const res = await reqInsertJkz({
  //       area_name: value,
  //     });
  //     console.log("新增成功", res);
  //     ElMessage.success("新增成功");
  //     handlePagingQuery();
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //   }
  // });
};

const handleDeleteData = (row) => {
  const { id } = row;
  ElMessageBox.confirm("确认删除吗,删除后不可恢复?", "删除警告", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      try {
        console.log(id);
        const res = await reqDelJkz({
          id,
        });
        console.log("删除成功", res);
        ElMessage.success("删除成功");
        handlePagingQuery();
      } catch (error) {
        console.log(error);
        ElMessage.success("删除失败");
      } finally {
      }
    })
    .catch(() => {});
};

const handleUpdateData = (row, column) => {
  if (column.property === "id") {
    return;
  }
  let _data = _.cloneDeep(row);
  ElMessageBox.prompt(`请输入${column.label}`, `修改${column.label}`, {
    confirmButtonText: "修改",
    cancelButtonText: "取消",
    inputValue: _data[column.property],
  }).then(async ({ value }) => {
    _data[column.property] = value;
    try {
      const res = await reqUpdateArea(_data);
      console.log("修改成功", res);
      ElMessage.success("修改成功");
      handlePagingQuery();
    } catch (error) {
      console.log(error);
    } finally {
    }
  });
};

handlePagingQuery();
</script>

<style lang="scss" scoped>
.table-search {
  margin-top: 16px;
  width: 300px;
}

.table-list {
  margin-top: 16px;
  min-height: 440px;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  align-items: center;

  h5 {
    margin-right: 16px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar {
  width: 200px;
  height: 200px;
}
</style>
